
import { reactive } from 'vue';

const config = reactive({
    APP_DEV: false,
    API_URL_DEV: "http://localhost:4000/api",
    API_URL_PROD: "https://contact.kuboti.com/api",
    WEBSOCKET_PROD:"wss://contact.kuboti.com/socket",
    WEBSOCKET_DEV:"ws://localhost:3000",
    API_URL_ASTERISK: "api.kuboti.com",
    API_WS_ASTERISK: "wss://api.kuboti.com:8089/ws",
    API_URL_GESTION: "http://144.217.5.122/alpha/cobranza/",
    URL_FORM:"https://cobranza.kuboti.com/cobranza/PQ005PROGRESIVO/wfCUS020GestionProgresivo.aspx?",
    NEW_URL_FORM:"https://cobranza.kuboti.com/contactoeficaz/cobranza/PQ005PROGRESIVO/wfCUS020GestionProgresivo.aspx?",
  // Otras configuraciones que desees...
});

export default config;
